<template>
	<div class="container">

		<div class="doc">
			

			<div class="item">
				<div class="head1">Informationspflicht gemäß Artikel 13 DSGVO</div>
								<div class="head2"></div>
				<div class="cont">
					Datenschutz und der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen. Mit diesem Aushang informieren wir Sie gemäß unserer Informationspflicht über die allgemeinen personenbezogenen Daten die wir von unseren Kunden im Betrieb verarbeiten.
				</div>
			</div>

			<div class="item">
				<div class="head2">Die Verarbeitungen Ihrer Daten erfolgt auf Basis folgender Grundlagen</div>
				<div class="cont">
					      <ul>
					      	<li>Erfüllung eines Vertrages mit den Betroffenen oder Durchführung vorvertraglicher Maßnahmen</li>
					      	<li>Berechtigte Interessen des Verantwortlichen oder eines 	Dritten</li>
					      </ul>
				</div>
			</div>

			<div class="item">
				<div class="head2">Rechtsgrundlage Vertragserfüllung
      Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw. zur Durchfürung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen.</div>
				<div class="cont">
					Zwecke:
					<ul>
						<li>Besichtigung in Ihrem Garten im Rahmen einer Beratung oder Angebotslegung</li>
						<li>Auftragserfüllung</li>
						<li>Rechnungslegung</li>
						<li>Information über von ihnen bestellte Pflanzen und/oder Auslieferung von Bestellungen</li>
						<li>Pflegearbeiten</li>
					</ul>
				</div>
			</div>

			<div class="item">
				<div class="head2">Rechtsgrundlage berechtigte Interessen</div>
				<div class="cont">
					Wir verarbeiten Daten über Sie aufgrund unserer berechtigten Interessen oder denen eines Dritten, 
wenn Sie im Rahmen einer Onlineauktion der Kleinen Zeitung eines unserer Angebote ersteigert haben, bis zur erfolgten Einlösung des Bezugszertifikat
				</div>
			</div>

			<div class="item">
				<div class="head2">Speicherdauer/Löschungsfrist</div>
				<div class="cont">
					unternehmensrechtlichen Aufbewahrungspflicht nach §§ 190, 212 UGB: 7 Jahre <br>
					umsatzsteuerrechtlichen Aufbewahrungspflicht nach § 18 Abs 2 3. Unterabsatz: 7 Jahre <br>
					Weitere Löschungsfristen können Ihnen im Rahmen der Auskunftserteilung übermittelt werden. <br> Eine aktualisierte Listung der gesetzlichen Fristen finden Sie auch  auf der Website der österreichischen Wirtschaftskammer: https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-speicher-und-aufbewahrungsfristen.html
				</div>
			</div>

			<div class="item">
				<div class="head2">Datenübermittlung</div>
				<div class="cont">
					Wir geben Ihre Daten  nicht weiter. 
				</div>
			</div>

			<div class="item">
				<div class="head2">Rechtsbehelfsbelehrung</div>
				<div class="cont">
					Da wir die Daten in unseren berechtigten Interessen verarbeiten, haben Sie grundsätzlich ein Widerspruchsrecht, wenn bei Ihnen Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben, die gegen diese Verarbeitung sprechen.
Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an uns.
Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist die Datenschutzbehörde zuständig.
				</div>
			</div>

			<div class="item">
				<div class="head2">KONTAKTADRESSEN</div>
				<div class="cont">
					Aufsichtsbehörde für die Einhaltung datenschutzrechtlicher Bestimmungen in Österreich:
Österreichische Datenschutzbehörde Wickenburggasse 8-10, 1080 Wien, Telefon: +43 1 531 15-202525, Telefax: +43 1 531 15-202690, E: dsb@dsb.gv.at, W: http://www.dsb.gv.at

				</div>
			</div>

			<div class="item">
				<div class="head2">Wenn Sie Ihre Rechte in Anspruch nehmen wollen wenden Sie Sich bitte an:</div>
				<div class="cont">
					Viridarium Gartenkontemplation, Fast & Pauritsch OG, <br>
Marianne Fast 0664 7903763 <br>
marianne.fast@viridarium.at
				</div>
			</div>
		
		</div>

		</div> <!-- container end -->

</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Datenschutz',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

div.doc {
	max-width: 50rem;
	margin: 0 auto;
	@media (max-width: 600px) {
		max-width: 22rem;
	}

	& div.head1 {
		text-align: center;
		color: gray;
		font-size: 1.2rem;
		font-weight: bold;
		margin: .6rem 0;
	}

	& div.head2 {
		text-align: left;
		color: var(--c12);
		font-size: 1.1rem;
		font-weight: bold;
margin: 1.4rem 0 0rem 0;
	}
	
	& div.item {
		border-bottom: 1px solid #ddd;
		padding-bottom: 0.4rem;
		
		&:last-child {
			border:none;
		}
	}
}


</style>
