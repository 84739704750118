import { render, staticRenderFns } from "./Informationspflicht.vue?vue&type=template&id=370d90ff&scoped=true&"
import script from "./Informationspflicht.vue?vue&type=script&lang=js&"
export * from "./Informationspflicht.vue?vue&type=script&lang=js&"
import style0 from "./Informationspflicht.vue?vue&type=style&index=0&id=370d90ff&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370d90ff",
  null
  
)

export default component.exports